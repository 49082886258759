<template lang="pug">
	.card.card-custom.gutter-b
		.card-header
			.card-title
				.card-label Raw Data
			.card-toolbar
		.card-body
			loading(:active.sync="busy", :is-full-page="false")
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							dw-date-time-picker(v-model="filters.datePicker")
							.form-group
								label Keyword
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
							.form-group
								label Event Name
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.event_name")
							.form-group
								label Report Type
								select.form-control(v-model="filters.report_type")
									option(value="") - All -
									option(v-for="o in reportTypeOptions", :value="o.v") {{ o.t }}
							.form-group
								label MMP Account
								select.form-control(v-model="filters.mmp_account_id")
									option(:value="null") - All -
									option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
							.form-group
								label Media Source
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.media_source")
							.form-group(style="width: 230px;")
								label Asset
								v-select.select-asset(v-model="selectedAsset", :options="assetOptions", label="name")
									template(v-slot:option="option")
										span.select-asset-option
											span.select-asset-icon
												img(:src="option.icon || ''")
											span.select-asset-text
												span.select-asset-name {{ option.name }}
												span.select-asset-package {{ option.package_id }}

							.form-group.no-label
								button.btn.btn-primary(type="submit") Go

			p.clearfix &nbsp;

			p Found <strong>{{ paginate.total }}</strong> records

			table.table.table-bordered.table-responsive.table-compact.table-report-raw-data
				thead
					tr
						th ID
						th MMP
						th Account
						th Asset
						th Type
						th(v-for="f in selectedFields", :class="'col-' + f.field") {{ f.label }}
						//th Actions
				tbody
					tr(v-if="records.length===0")
						td(:colspan="numCols") No matching records were found
					tr(v-for="r in records", :key="r.id", :class="'row-type-'+r.report_type")
						td {{ r.id }}
						td {{ r.mmp_account.mmp }}
						td [{{ r.mmp_account.id }}] {{ r.mmp_account.name }}
						td [{{  r.asset.id }}] {{ r.asset.name }}
							div
								small.text-muted {{ r.asset.package_id }}
						td.col-report-type {{ r.report_type }}
						td(v-for="f in selectedFields", :class="'col-' + f.field")
							span(:class="'field-'+f.field") {{ r[f.field] }}

			paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.table-report-raw-data {

	td {
		font-size: 11px;
	}

	.field-ad_id,
	.field-adset_id,
	.field-event_value {
		display: inline-block;
		width: 200px;
		overflow: auto;
		line-break: normal;
		word-break: normal;
		background: rgba(0,0,0,0.05);
		border-radius: 5px;
		padding: 3px;
		font-size: 11px;
	}

	th.col-event_name {
		background: rgba(200, 200, 255, 0.2);
	}

	td.col-event_name {
		background: rgba(200, 200, 255, 0.2);
	}

	tr.row-type-installs_report {
		background: rgba(50,255,50,0.05);
	}
	tr.row-type-in_app_events_report {}
	tr.row-type-blocked_installs_report {}
	tr.row-type-detection {
		background: rgba(255,50,50,0.1);
	}
	tr.row-type-blocked_in_app_events_report {}
	tr.row-type-fraud-post-inapps {
		background: rgba(255,100,100,0.05);
	}
	tr.row-type-blocked_clicks_report {
		background: rgba(255,255,100,0.07);
	}

}

</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){
	let resp = await Vue.$api.get('/raw-data/getAppsflyerFields');
	let fields = resp.fields;

	let assetResult = await Vue.$api.get('/assets/getList', {page: 1, limit: 1000});
	let mmpAccountOptions = await Vue.$dwData.mmpAccount.getOptions();

	return vm => {
		vm.fields = fields;
		vm.assetOptions = assetResult.records;
		vm.mmpAccountOptions = mmpAccountOptions;
		// vm.loadData();
		return vm;
	};
}

export default {
	name: 'RawDataReport',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				},
				keyword: '',
				media_source: '',
				report_type: '',
				event_name: '',
				source: '',
				mmp_account_id: null,
			},
			mmpAccountOptions: [],
			assetOptions: [],
			selectedAsset: null,
			reportTypeOptions: [
				{v: 'installs_report', t: 'Installs' },
				{v: 'in_app_events_report', t: 'In-App Events' },
				{v: 'blocked_installs_report', t: 'Blocked Installs' },
				{v: 'blocked_in_app_events_report', t: 'Blocked In-App Events' },
				{v: 'detection', t: 'Detection (Post Attr)' },
				{v: 'fraud-post-inapps', t: 'Fraud Post In-App' },
				{v: 'blocked_clicks_report', t: 'Blocked Clicks' },
			],
			records: [],
			fields: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Reports' },
			{ title: 'Raw Data' }
		]);
	},
	computed: {
		numCols(){
			return 4 + this.selectedFields.length;
		},
		selectedFields(){
			return this.fields.filter(f => f.selected);
		}
	},
	methods: {
		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = {
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				event_name: this.filters.event_name,
				media_source: this.filters.media_source,
				report_type: this.filters.report_type,
				asset_id: this.selectedAsset ? this.selectedAsset.id : 0,
				mmp_account_id: this.filters.mmp_account_id
			};
			try {
				let resp = await this.$api.post('/raw-data/query', data);
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
}
</script>
